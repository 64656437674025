.deleteButton {
  width: var(--input-height-admin);
  height: var(--input-height-admin);
  border-radius: var(--base-border-radius);
  background-color: var(--gray-color);
  font-weight: 500;

  &.disabled {
    color: var(--inactive-color);
  }
}
