.radioGroup {
  display: flex;
  gap: var(--size-standard);
  align-items: center;

  .inner {
    display: flex;
    gap: var(--size-micro);
    align-items: center;
  }
}
