@import '../../../common/styles/mixins';

.inputWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--size-mini);
}

.input {
  width: var(--input-width-short);
  height: var(--input-height-admin);
  cursor: default;

  &.active {
    @include active-input;
    cursor: initial;
  }

  &.error {
    @include error-input;
  }
}

.helperText {
  @include font-caption;
  color: var(--alert-color)
}
