.inputOrderWrapper {
  display: flex;
  gap: var(--size-mini);
  align-items: center;
}

.sortButton {
  height: 22px;
  padding-inline: var(--size-micro);
  border-radius: 4px;
  background-color: var(--light-gray-07);
  box-shadow: var(--element-shadow);
}
