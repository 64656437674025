@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400;500;600;700;800&display=swap');

@font-face {
  font-family: 'releway-regular';
  src: url('../../assets/fonts/Raleway/Raleway-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'releway-medium';
  src: url('../../assets/fonts/Raleway/Raleway-Medium.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'releway-semi-bold';
  src: url('../../assets/fonts/Raleway/Raleway-SemiBold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'releway-bold';
  src: url('../../assets/fonts/Raleway/Raleway-Bold.ttf') format('truetype');
  font-display: swap;
}
