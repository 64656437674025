@import '../../../common/styles/mixins';

.resultBlock {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.columnsGrid {
  display: grid;
  grid-template-columns: 2fr 4fr 10fr 1fr 1fr 1fr 1fr;
  gap: var(--size-standard);
  padding-bottom: var(--size-mini);
}

.tableHeader {
  @include font-body-bold;
  margin-right: 17px;
  padding-bottom: 0;
}

.listWrapper {
  flex: 1;
  overflow-y: scroll;

  .item {
    position: relative;
    align-items: center;

    &:not(:last-child)::after {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 1px;
      background-color: var(--inactive-color);
    }
  }

  .cellId {
    display: flex;
    align-items: center;
    gap: var(--size-standard);
  }

  .cellName em {
    color: var(--brand-color);
  }

  .copyButton {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
