.section {
  padding-bottom: var(--size-grand);
}

.container {
  max-width: var(--content-max-width-slim) !important;

  p {
    font-size: 14px;
  }
}

.textCenter {
  text-align: center;
}

.mt1 {
  margin-top: var(--size-standard);
}

.link {
  color: var(--link-color);
}

.bold {
  font-weight: 700;
}

.markBlocK {
  display: flex;
  gap: var(--size-standard);
}
