.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--size-huge);
}

.inputWrapper {
  position: relative;
}

.asideButton {
  position: absolute;
  top: 50%;
  right: -16px;
  transform: translate(100%, -50%);
}

.input {
  width: var(--button-min-widths);
  height: var(--size-max);
}

.titleWrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--header-height);
}

.errorMessage {
  color: var(--alert-color);
}
