.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: var(--button-min-widths);
  height: var(--button-height);
  padding-inline: var(--size-huge);
  border-radius: var(--size-mini);
  background-color: var(--brand-color);
  font-weight: 600;
  color: var(--font-light-color);

  &.disabled {
    background-color: var(--inactive-color);
    cursor: not-allowed;
  }
}

.outlined {
  border: 2px solid var(--dark-color);
  background-color: transparent;
  color: var(--font-black-color);

  &.disabled {
    border-color: var(--font-gray-color);
    background-color: transparent;
    color: var(--font-gray-color);
    cursor: not-allowed;
  }
}

.blue {
  background-color: var(--blue-color);
}
