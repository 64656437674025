@import '../../../common/styles/mixins';

.row {
  display: grid;
  grid-template-columns: 25% 60%;
  gap: var(--size-standard);
  align-items: center;
  padding-bottom: var(--size-mini);

  &:not(:last-child) {
    border-bottom: 1px solid var(--gray-color);
  }

  dt {
    @include font-body-bold;
    line-height: normal;
  }

  dd {
    display: flex;
    flex-flow: row wrap;
    gap: var(--size-mini);

    em {
      color: var(--brand-color);
    }
  }
}

.success {
  padding-inline: var(--size-mini);
  background-color: var(--green-color);
  color: var(--light-color);
}

.warn {
  padding-inline: var(--size-mini);
  background-color: var(--orange-color);
  color: var(--light-color);
}

.alert {
  padding-inline: var(--size-mini);
  background-color: var(--alert-color);
  color: var(--light-color);
}

.avaImage {
  width: auto;
  height: 201px;
}

.badge {
  display: inline-flex;
  align-items: center;
  min-height: var(--input-height-admin);
  padding-inline: var(--size-mini);
  border-radius: 4px;
  border: 1px solid var(--dark-gray-color);
  background-color: transparent;
}

.itemWidget {
  display: inline-flex;
  align-items: center;
  gap: var(--size-mini);
  min-height: var(--input-height-admin);
  padding-inline: var(--size-mini);
  border-radius: 4px;
  border: 1px solid var(--light-gray-07);
  background-color: var(--inactive-color);

  &.success {
    background-color: var(--light-green);
    color: var(--green-color);
  }

  &.inactive {
    background-color: var(--light-alert);
  }
}

.modalWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--size-standard);

  .title {
    @include font-body-bold;
  }
}

.input {
  width: 210px;
}

.buttonWrapper {
  margin-top: var(--size-huge);

  button {
    margin-inline: auto;
  }
}
