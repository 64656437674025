.page {
  padding-bottom: var(--size-grand);
}

.blockStandard {
  display: flex;
  flex-direction: column;
  gap: var(--size-standard);
}

.blockMicro {
  display: flex;
  flex-direction: column;
  gap: var(--size-micro);
}

.resultBlock {
  display: flex;
  flex-direction: column;
  gap: var(--size-micro);
}

.blockRow {
  display: flex;
  gap: var(--size-standard);
  align-items: center;
}

.stringRow {
  display: flex;
  gap: var(--size-mini);
  align-items: center;
}
