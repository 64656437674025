@import '../../../common/styles/mixins';

.wrapper {
  overflow-y: scroll;
}

.item {
  display: flex;
  flex-direction: column;
  gap: var(--size-mini);
  padding-block: var(--size-mini);
  border-bottom: 1px solid var(--dark-gray-color);
}
