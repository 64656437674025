.contentBlock {
  display: flex;
  flex-direction: column;
  gap: var(--size-standard);
  overflow: hidden;
}

.main {
  display: flex;
  flex-direction: column;
  gap: var(--size-mini);
  padding-bottom: var(--size-grand);
  overflow-y: auto;
}
