.modalInner {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 90vw;
  max-height: 85vh;
  overflow-y: auto;
}

.invalidMessage {
  color: var(--alert-color);
}

.form {
  display: flex;
  flex-direction: column;
  gap: var(--size-standard);
}

.fieldset {
  display: flex;
  gap: var(--size-mini);
}

.deleteButtonWrapper {
  display: flex;
  align-items: flex-end;
}

.textAreaBlock {
  display: flex;
  flex-direction: column;
  gap: var(--size-mini);

  select {
    width: 178px;
    height: var(--input-height-admin);
  }

  input {
    width: var(--input-width-short);
    height: var(--input-height-admin);
  }

  &.name {
    flex-grow: 1;

    input {
      width: 100%;
    }
  }
}

.buttonsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.addButton {
  background-color: var(--blue-color);
}
