.wrapper {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--size-standard);
  border-radius: var(--size-micro);
  background-color: var(--inactive-color);

  input {
    padding-left: 0;
    border: 0;
  }
}

.buttonsWrapper {
  display: flex;
  gap: var(--size-standard);
}

.uploadButton {
  display: initial;
  width: auto;
}
