@import '../../../../common/styles/mixins';

.section {
  flex-grow: 1;
}

.displayContent {
  display: flex;
  flex-direction: column;
  gap: var(--size-standard);
  height: calc(100% - var(--fixed-bottom-panel-height));
  flex-grow: 1;
  padding-block: var(--size-huge);
  padding-inline: var(--size-standard);
  overflow-y: scroll;
}

.blockStandard {
  display: flex;
  flex-direction: column;
  gap: var(--size-standard);
}

.listBlock {
  display: flex;
  flex-direction: column;
  gap: var(--size-mini);
}

.rowBlockTop {
  display: flex;
  gap: var(--size-huge);
}

.plainRow {
  display: flex;
  gap: var(--size-mini);
  align-items: center;

  .icon {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 24px;
    height: 24px;
  }
}

.downsizeText {
  font-size: 13px;
}

.listWrapper {
  display: flex;
  flex-direction: column;
}

.listItem {
  display: flex;
  flex-direction: column;
  background-color: var(--light-gray-07);

  &.odd {
    background-color: var(--blue-color-02);
  }
}

.listItem_textBlock {
  font-size: 14px;

  .title {
    font-family: var(--font-kcal-semi-bold), sans-serif;
  }
}

.productId {
  padding-inline: var(--size-standard);
  font-size: 12px;
  color: var(--dark-gray-color);
}

.roundBullet {
  @include flex-center-center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid var(--dark-gray-color);
}

.priceBlock {
  img {
    width: 21px;
  }
}

/* COMMON */

.imageBlock {
  display: inline-block;
}

.imageWrapper {
  width: 424px;
  height: 314px;
  border: 2px dashed var(--dark-color);

  img {
    display: block;
  }
}

.itemRow {
  display: flex;
  gap: var(--size-standard);
  align-items: flex-start;
  justify-content: space-between;
  padding-block: var(--size-mini);
  padding-inline: var(--size-standard);

  .leftSide {
    display: flex;
    flex: 1;
    gap: var(--size-standard);
    align-items: flex-start;
  }

  .rightSide {
    display: flex;
    gap: var(--size-standard);
    align-items: flex-start;
  }
}

.cell {
  display: flex;
  flex-direction: column;
  gap: var(--size-mini);
  min-width: 50px;

  .title {
    font-size: 12px;
  }

  .value {
    font-family: var(--font-kcal-medium), sans-serif;
    font-size: 14px;
  }

  input {
    width: 120px;
  }
}

.totalDay {
  background-color: var(--light-yellow);
  font-weight: 600;
}

.total {
  margin-top: var(--size-standard);
  background-color: var(--orange-color);
}
