@import 'reset';
@import 'fonts';
@import 'variables';
@import 'mixins';
@import 'constants';

* {
  box-sizing: border-box;
}

body {
  height: 100vh;
  margin: 0;
  @include font-body;
  color: var(--font-black-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

main {
  flex: 1;
  padding-top: var(--size-max);
}

footer {
  padding-block: var(--size-standard);
  padding-inline: var(--section-padding-inline);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

h1 {
  @include font-h1;
}

h2 {
  @include font-h2;
}

h3 {
  @include font-h3;
}

h4 {
  @include font-h4;
}

h5 {
  @include font-h5;
}

input,
select,
textarea {
  padding-inline: var(--size-mini);
  border: 1px solid var(--gray-color);
  border-radius: var(--base-border-radius);
  font-family: var(--font-system), sans-serif;
}

.section {
  padding-inline: var(--section-padding-inline);
}

.container {
  max-width: var(--content-max-width);
  margin-inline: auto;
}

.link {
  color: var(--link-color);
}

.bold {
  font-family: var(--font-kcal-bold), sans-serif;
}

.green-font {
  color: var(--green-color);
}

.alert-font {
  color: var(--alert-color);
}

.desktopShow {
  display: none;

  @media(min-width: #{$breakpoint-desktop}) {
    display: flex;
  }
}

.tag {
  width: 90px;
  padding-inline: var(--size-mini);
  border-radius: 2px;
  font-family: var(--font-kcal-semi-bold), sans-serif;
  font-size: 12px;
  text-align: center;
  color: var(--light-color);
}

.tag_breakfast {
  background-color: #73b048;
}

.tag_snack-one {
  background-color: rgb(175, 215, 128);
}

.tag_lunch {
  background-color: #e5c34b;
}

.tag_snack-two {
  background-color: rgb(224, 204, 152);
}

.tag_dinner {
  background-color: #2b90de;
}

.tag_snack-three {
  background-color: #75a2c4;
}

.input-order {
  width: 38px;
  height: 22px;
  padding-inline: var(--size-micro);
}

.grid-2-Columns-1-3 {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: var(--size-mini);
  align-items: center;
}

.grid-2-Columns-1-6 {
  display: grid;
  grid-template-columns: 1fr 6fr;
  gap: var(--size-mini);
  align-items: center;
}
