@import '../../../common/styles/mixins.scss';

$category-field-width: 128px;

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.tableHeader {
  padding-inline: var(--size-standard) var(--size-max);
  padding-block: var(--size-mini);

  .stub {
    width: var(--input-width-short);
  }
}

.showIdButton {
  color: var(--brand-color);
}

.row {
  display: grid;
  grid-template-columns: 10fr 1fr 1fr 1fr 1fr 3fr;
  align-items: center;
  gap: var(--size-mini);
}

.subRow {
  display: flex;
  gap: var(--size-mini);

  .cellCategory {
    width: #{$category-field-width};
    height: var(--input-height-admin);

    &.active {
      @include active-input;
    }
  }

  .cellName {
    width: 100%;
  }
}

.listWrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: calc(100% - var(--fixed-bottom-panel-height) - var(--input-height-admin));
  padding-block: var(--size-standard);
  padding-inline: var(--size-standard);
  gap: var(--size-standard);
  overflow-y: scroll;
}
