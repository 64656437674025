@import '../../../common/styles/mixins';

.section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.main {
  display: flex;
  flex-direction: column;
  gap: var(--size-standard);
  overflow: hidden;
}

.infoBlock {
  display: flex;
  gap: var(--size-mini);
  align-items: center;

  dd {
    @include font-body-bold;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--size-max);
  padding-block: var(--size-mini);

  .display {
    display: flex;
    gap: var(--size-mini);
  }
}
