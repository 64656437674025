.wrapper {
  flex-grow: 1;
}

.listWrapper {
  height: calc(100% - var(--fixed-bottom-panel-height));
  flex-grow: 1;
  padding-block: var(--size-huge);
  padding-inline: var(--size-standard);
  overflow-y: scroll;
}

.itemWrapper {
  display: flex;
  gap: var(--size-huge);

  &:not(:first-child) {
    margin-top: 120px;
  }
}

.selectInput {
  height: var(--size-max);
}

.leftBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.item {
  display: flex;
  gap: var(--size-max);
}

.imageWrapper {
  width: 424px;
  height: 314px;
  border: 2px dashed var(--dark-color);

  img {
    position: relative;
    display: block;
    z-index: -1;
  }
}

.imageInfo {
  display: flex;
  gap: var(--size-standard);
  margin-top: var(--size-standard);

  dt {
    font-weight: 600;
  }
}

.rightBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.description {
  display: flex;
  flex-direction: column;
  gap: var(--size-standard);
}

.itemId {
  display: flex;
  gap: var(--size-standard);
  align-items: center;
}

.categoriesWrapper {
  display: flex;
  flex-flow: row wrap;
  gap: var(--size-standard);
}

.inputsBlock {
  display: flex;
  flex-direction: column;
  gap: var(--size-mini);

  .buttonWrapper {
    display: flex;
    justify-content: flex-end;

    button {
      width: auto;
    }
  }
}

.inputName {
  width: auto;
}

.inputsRow {
  display: flex;
  gap: var(--size-mini);
}

.inputProduct {
  width: 400px;
}

.inputId {
  width: var(--input-product-id-width);
}

.buttonsWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: var(--size-standard);

  .leftSide,
  .rightSide {
    display: flex;
    gap: var(--size-standard);
  }
}
