.wrapper {
  max-width: 320px;
  height: 100%;
  padding: var(--size-huge);
  background-color: var(--light-yellow);
  overflow-y: auto;
}

.list {
  display: flex;
  flex-direction: column;
  gap: var(--size-mini);

  li {
    display: flex;
    gap: var(--size-mini);
    align-items: center;
    padding-inline: var(--size-mini);
    cursor: pointer;

    &.active {
      background-color: var(--brand-color);
      color: var(--font-light-color);

      &:hover {
        color: var(--font-light-color);
      }
    }

    &:hover {
      color: var(--brand-color);
    }
  }
}
