.container {
  flex-direction: column;
  gap: var(--size-max);
  overflow-y: auto;
}

.block {
  display: flex;
  flex-direction: column;
  gap: var(--size-standard);
}

.list {
  display: flex;
  flex-direction: column;
  gap: var(--size-mini);
}

.listItem {
  display: flex;
  gap: var(--size-micro);
  align-items: center;
}
