@import '../../../common/styles/mixins';

.homeBanner {
  @media(min-width: #{$breakpoint-desktop}) {
    padding-inline: var(--size-max);
  }
}

.leftSide {
  display: flex;
  flex-direction: column;
  gap: var(--size-huge);
}

.title {
  text-align: center;
  @include font-h1;

  @media(min-width: #{$breakpoint-desktop}) {
    text-align: left;
  }
}

.badgesBlock {
  display: flex;
  flex-direction: column;
  gap: var(--size-standard);
  align-items: center;

  @media(min-width: #{$breakpoint-desktop}) {
    flex-direction: row;
  }
}

.imageButton {
  width: 184px;
  height: auto;

  @media(min-width: #{$breakpoint-desktop}) {
    width: auto;
    height: 56px;
  }

  img {
    display: block;
    width: 100%;
    height: auto;

    @media(min-width: #{$breakpoint-desktop}) {
      width: auto;
      height: 100%;
    }
  }
}
