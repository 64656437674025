@import 'constants';

@mixin font-caption {
  font-family: var(--font-kcal-regular), sans-serif;
  font-size: 14px;
  line-height: 16px;
}

@mixin font-body {
  font-family: var(--font-kcal-regular), sans-serif;
  font-size: var(--base-font-size);
  line-height: 24px;
}

@mixin font-body-bold {
  font-family: var(--font-kcal-semi-bold), sans-serif;
  font-size: var(--base-font-size);
  line-height: 24px;
}

@mixin font-h1-desktop {
  font-family: var(--font-kcal-semi-bold), sans-serif;
  font-size: 32px;
  line-height: 38px;
}

@mixin font-h1-mobile {
  font-family: var(--font-kcal-semi-bold), sans-serif;
  font-size: 28px;
  line-height: 32px;
}

@mixin font-h1 {
  @include font-h1-mobile;

  @media(min-width: #{$breakpoint-desktop}) {
    @include font-h1-desktop;
  }
}

@mixin font-h2-desktop {
  font-family: var(--font-kcal-semi-bold), sans-serif;
  font-size: 24px;
  line-height: 28px;
}

@mixin font-h2-mobile {
  font-family: var(--font-kcal-semi-bold), sans-serif;
  font-size: 20px;
  line-height: 28px;
}

@mixin font-h2 {
  @include font-h2-mobile;

  @media(min-width: #{$breakpoint-desktop}) {
    @include font-h2-desktop;
  }
}

@mixin font-h3-desktop {
  font-family: var(--font-kcal-bold), sans-serif;
  font-size: 18px;
  line-height: 24px;
}

@mixin font-h3-mobile {
  font-family: var(--font-kcal-bold), sans-serif;
  font-size: 18px;
  line-height: 20px;
}

@mixin font-h3 {
  @include font-h3-mobile;

  @media(min-width: #{$breakpoint-desktop}) {
    @include font-h3-desktop;
  }
}

@mixin font-h4 {
  font-family: var(--font-kcal-bold), sans-serif;
  font-size: 16px;
  line-height: 24px;
}

@mixin font-h5 {
  font-family: var(--font-kcal-semi-bold), sans-serif;
  font-size: 16px;
  line-height: 24px;
}

@mixin active-input {
  outline: 1px solid var(--yellow-color);
}

@mixin error-input {
  outline: 1px solid var(--alert-color);
}

@mixin cross-button {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--cross-button-size);
  height: var(--cross-button-size);
  border-radius: var(--base-border-radius);
  background-color: var(--brand-color);
  color: var(--light-color);
  font-weight: 700;
  cursor: pointer;
}

@mixin flex-center-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
