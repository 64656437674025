h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
}

a {
  color: initial;
  text-decoration: unset;
}

button {
  outline: unset;
  border: inherit;
  padding: inherit;
  background-color: unset;
  cursor: pointer;
}

input,
select,
textarea {
  border: 0;
  outline: 0;
}

input[type='radio'] {
  margin: 0;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

fieldset,
legend {
  margin: 0;
  padding: 0;
  padding-inline: 0;
  border: 0;
}

dl {
  margin: 0;
}

dt,
dd {
  display: inline;
  margin: 0;
}
