.container {
  flex-direction: column;
  flex: 1;
  overflow: auto;
}

.row {
  display: grid;
  grid-template-columns: 10fr 2fr 2fr 2fr 2fr 1fr;
}

.productsList {
  margin-top: var(--size-huge);
}

.item {
  padding-block: var(--size-mini);

  &:hover {
    background-color: var(--light-yellow);
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--inactive-color);
  }
}

.buttonCell {
  justify-self: end;
}
