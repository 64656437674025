.searchBlock {
  display: flex;
  gap: var(--size-standard);
  align-items: center;
}

.inputHolder {
  position: relative;

  input {
    height: 32px;
    width: 352px;
  }
}

.inputSpinner {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
}
