.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: var(--fixed-bottom-panel-height);
  padding-inline: var(--size-standard);
  border-top: 1px solid var(--gray-color);
  background-color: var(--light-color);
}

.rightSide {
  display: flex;
  gap: var(--size-standard);
}
