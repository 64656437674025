@import './constants';

:root {
  // fonts
  --font-kcal-regular: 'releway-regular', sans-serif;
  --font-kcal-medium: 'releway-medium', sans-serif;
  --font-kcal-semi-bold: 'releway-semi-bold', sans-serif;
  --font-kcal-bold: 'releway-bold', sans-serif;
  --font-system: 'JetBrains Mono', monospace, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  --base-font-size: 16px;
  --font-light-color: #f8f8f8;
  --font-black-color: #343434;
  --font-gray-color: #c4c4c4;

  // layout
  --section-padding-inline: 24px;
  --content-max-width: 1152px;
  --content-max-width-slim: 768px;

  // component
  --header-height: 64px;
  --button-min-width: 150px;
  --button-height: 38px;
  --input-height: 24px;
  --input-height-admin: 28px;
  --input-width-short: 78px;
  --input-width-medium: 182px;
  --input-product-id-width: 214px;
  --fixed-bottom-panel-height: 60px;
  --base-border-radius: 4px;
  --cross-button-size: #{$cross-button-size};

  // sizes
  --size-grand: 48px;
  --size-max: 36px;
  --size-huge: 24px;
  --size-standard: 16px;
  --size-medium: 12px;
  --size-mini: 8px;
  --size-micro: 4px;

  // colors
  --light-color: #fff;
  --dark-color: #343434;
  --semi-light-color: #ffffff80;
  --brand-color: #ff401d;
  --brand-color-semi: rgba(255, 64, 29, 0.11);
  --alert-color: #c92525;
  --light-alert: #e7b4b4;
  --alert-button-color: #c96d6d;
  --blue-color: #5b8fd3;
  --blue-color-07: rgba(91, 143, 211, 0.7);
  --blue-color-02: rgba(91, 143, 211, 0.2);
  --link-color: #0000ff;
  --dark-gray-color: #7e7e7e;
  --gray-color: #c7c7c7;
  --light-gray-07: rgba(233, 233, 233, 0.7);
  --inactive-color: #e3e3e3;
  --orange-color: #ffaa59;
  --yellow-color: #f0e300;
  --light-yellow: #fffad8;
  --green-color: #0da300;
  --light-green: #ecf8ef;
  --element-shadow: 0 0 3px 0 rgba(84, 84, 84, 0.3);
}
