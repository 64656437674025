.navWrapper {
  display: flex;
  align-items: center;
  gap: var(--size-huge);
}

.container {
  padding-inline: var(--section-padding-inline);
}

.navigation {
  display: flex;
  gap: var(--size-standard);

  .active {
    background-color: var(--brand-color-semi);
  }

  a {
    padding: var(--size-mini) var(--size-standard);
    border-radius: var(--size-mini);

    &:hover {
      background-color: var(--brand-color-semi);
    }
  }
}
