.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: var(--semi-light-color);
}

.modal {
  position: relative;
  min-width: 520px;
  padding: var(--size-huge);
  border-radius: 8px;
  background-color: var(--light-color);
  box-shadow: 0 0 25px rgb(171 153 153 / 50%);
}

.closeCross {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  display: flex;
  width: 28px;
  height: 28px;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--gray-color);
  border-radius: 50%;
  background-color: var(--light-color);
  font-family: sans-serif;
  cursor: pointer;
}
