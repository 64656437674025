.header {
  padding-inline: var(--size-standard);
  box-shadow: 0 10px 30px rgba(187, 191, 201, 0.25);
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--header-height);
}

.logoLink {
  display: flex;
  align-items: center;
  gap: var(--size-standard);
}

.logo {
  height: 32px;
  border-radius: 5px;
  overflow: hidden;
}

.text {
  display: flex;
  font-family: var(--font-kcal-semi-bold), sans-serif;
  font-size: 21px;
}
