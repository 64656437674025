.imageFrame {
  position: relative;
  border: 1px dashed var(--dark-color);

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  &.background {
    width: 215px;
    height: 466px;
  }

  &.main {
    width: 215px;
    height: 215px;
  }
}
