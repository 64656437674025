.roundLabel {
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.allow {
  background-color: var(--green-color);
}

.pending {
  background-color: var(--orange-color);
}
