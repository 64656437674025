.admin-page-wrapper {
  display: flex;
  height: calc(100vh - var(--header-height));
}

.page-layout-fixed-height {
  flex-direction: column;
  gap: var(--size-max);
  padding-top: var(--size-huge);
  padding-inline: var(--section-padding-inline);
  overflow-y: auto;
}
