.modalWrapper {
  max-height: 90vh;
  padding-inline: var(--size-max);
  overflow-y: auto;
}

.header {
  display: flex;
  gap: var(--size-grand);

  .leftSide {
    display: flex;
    flex-direction: column;
    gap: var(--size-mini);
  }
}

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--size-standard);
  min-width: 80vw;
  margin-top: var(--size-huge);
}

.fieldset {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--size-huge);
  padding: var(--size-medium);
  border: 1px solid var(--gray-color);
  border-radius: var(--base-border-radius);

  legend {
    display: flex;
    gap: var(--size-mini);
    align-items: center;
    padding-inline: var(--size-standard);
  }
}

.textAreaBlock {
  input, select {
    min-width: 600px;
    height: var(--input-height-admin);
  }

  .flexInput {
    min-width: unset;
  }
}

.mediumInputWidth input {
  width: var(--input-width-medium);
}

.textAreaBlock {
  align-items: flex-start;

  textarea {
    min-width: 600px;
    min-height: 150px;
  }
}

.checkBoxBlock {
  display: flex;
  gap: var(--size-mini);
  align-items: center;
}

.modalProducts {
  display: flex;
  flex-direction: column;

  .productRow {
    background-color: var(--light-gray-07);
  }

  .odd {
    background-color: var(--blue-color-02);
  }

  .orderBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--size-mini);
  }

  .productTextBlock {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: var(--size-mini);

    textarea {
      flex: 1;
    }
  }
}

.addProductBlock {
  display: flex;
  flex-direction: column;
  gap: var(--size-standard);
}

.addProductItem {
  display: flex;
  gap: var(--size-standard);

  button {
    width: auto;
    min-width: 150px;
  }

  input {
    width: 300px;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  margin-top: var(--size-huge);

  .rightSide {
    display: flex;
    gap: var(--size-standard);
  }
}
