.tabsWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--size-standard);
  max-height: 100%;
}

.tabsRow {
  display: flex;
  color: var(--font-black-color);
  background-color: var(--light-color);
}
